import { TranslationsPaths } from '@iadvize/livechat-translations-types';
import { Channel } from '@iadvize/notifications-library/esm/constants';
import {
  getNotificationContainerCssPosition,
  getSimplePositionFromNotificationTemplate,
} from '@iadvize/notifications-library/esm/helpers/position-helper';
import {
  BadgeNotificationTemplate,
  EmbeddedConversationStarterTemplate,
} from '@iadvize/notifications-library/esm/entities/notification';
import {
  addIfDoesNotExistIframe,
  destroyIframeByID,
  unmountRootNodeFromIframe,
} from '../../lib/IframeManager';
import {
  getEventManager,
  getIdz,
  getIsContactOngoing,
} from '../shared/globals';
import { makeIframeId } from './helpers';

import {
  PerformanceMarks,
  startPerformanceMeasure,
} from '../../lib/performance';
import { getPublicPropertiesStore } from '../entry/publicMethods/publicProperties/registerPublicProperties';
import { getTagConfig } from '../entry/tagConfig';
import translationService from '../live/src/i18n/translationService';
import { DeviceInformation } from '../shared/DeviceInformationExtractor';
import { draggable } from '../shared/draggable/draggable';
import { setDraggableInstance } from '../shared/draggable/getDraggableInstance';
import { getStaticUrl } from '../shared/staticUrl';
import { ClassicParameters } from '../shared/types/rules';
import deviceTypeToMediaType from '../shared/utils/deviceTypeToMediaType';
import sentryLauncher from '../shared/utils/sentry/sentryLauncher';
import { NotificationIframeGlobals } from './globalTypes';
import {
  EngagementAcceptedOptionalActionParameters,
  NotificationData,
  NotificationOrchestratorEmitter,
} from './types';
import { getClientId } from '../entry/visitorContextConfig/websiteConfiguration';
import { InputDeviceTypes } from '../shared/InputDeviceTypes';

const scripts = [`${getStaticUrl()}notification.js`];
const stylesheets = [`${getStaticUrl()}notification.css`];
const appContainerId = 'notificationApp';

const draggableInstance = draggable();
setDraggableInstance(draggableInstance);

const embeddedConversationStarterContainer = document.createElement('div');
embeddedConversationStarterContainer.id =
  'embeddedConversationStarterContainer';

/*_________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const displayNotification = (
  notificationData: NotificationData,
  deviceType: DeviceInformation['deviceType'],
  lang: keyof TranslationsPaths,
  notificationOrchestratorEmitter: NotificationOrchestratorEmitter,
  routingRuleId: string,
  ruleId: number,
) => {
  const idz = getIdz();
  const mediaType = deviceTypeToMediaType(deviceType);
  const { zIndex } = idz.chatboxTemplate.templateAttributes;

  const template = notificationData.notification;
  const templateType =
    mediaType === 'MOBILE' &&
    template.templateType !== 'EMBEDDED_CONVERSATION_STARTER'
      ? template?.mobileTemplateType
      : template?.templateType;

  const isEmbeddedConversationStarter =
    templateType === 'EMBEDDED_CONVERSATION_STARTER';

  const cssPosition = getNotificationContainerCssPosition(
    notificationData.notification,
    mediaType,
  );

  const simplePosition = isEmbeddedConversationStarter
    ? undefined
    : getSimplePositionFromNotificationTemplate(
        notificationData.notification,
        mediaType,
      );

  if (getTagConfig().mode === 'sandboxed' && cssPosition) {
    cssPosition.bottom = '0';
    cssPosition.left = '0';
    cssPosition.right = '0';
  }

  const iframeStyle = {
    ...(cssPosition || {}),
    display: 'block',
    position: isEmbeddedConversationStarter ? 'static' : 'fixed',
    height: isEmbeddedConversationStarter ? '0' : 'auto',
    width: isEmbeddedConversationStarter ? '100%' : 'auto',
    maxWidth: '100%',
    'border-style': 'none',
    'z-index': zIndex || '1000000000', //______________________________
  };

  const sizeIframeToBadgeSize = (badgeSize: number, badgesQuantity = 1) => {
    iframeStyle.width = `${badgeSize + 30}px`;
    iframeStyle.height = `${badgeSize * badgesQuantity + 30}px`;
  };

  //_________________________________________________________________
  //_________________________________________________________________
  //___________________________________________________________________
  if (['BADGE', 'MINI_BADGE'].includes(templateType)) {
    const badgesQuantity = (notificationData.parameters as ClassicParameters)
      .buttons.length;
    sizeIframeToBadgeSize(
      (template as BadgeNotificationTemplate).templateAttributes.badgeSize,
      badgesQuantity,
    );
  }

  startPerformanceMeasure(PerformanceMarks.NOTIFICATION_DISPLAY);

  const iFrameId = makeIframeId(notificationData);

  const globals: NotificationIframeGlobals = {
    appContainerId,
    chatboxTemplate: idz.chatboxTemplate,
    clientId: getClientId() || 0,
    curlang: idz.curlang,
    deviceType,
    featureFlags: idz.featureFlags,
    notificationData,
    notificationId: notificationData.id,
    notificationOrchestratorEmitter,
    platform: idz.platform,
    publicPropertiesStore: getPublicPropertiesStore(),
    routingRuleId,
    ruleId,
    sentryConfig: idz.sentry,
    sentryLauncher,
    translationService,
    version: process.env.BUILD_IDENTIFIER!,
    vProf: idz.vProf,
    vuid: idz.vuid,
    websiteId: idz.website_id,
    onEngagementAccepted: (
      channel: Channel,
      optionalActionParameters: EngagementAcceptedOptionalActionParameters,
    ) =>
      !draggableInstance.getIsDragging() &&
      notificationOrchestratorEmitter.emit('engagementAccepted', {
        notificationData,
        ruleId,
        routingRuleId,
        channel,
        optionalActionParameters,
        position: simplePosition,
      }),
    isConversationStarted: getIsContactOngoing(),
    onOpen: () => {
      getEventManager().emit(
        'engagement.showChatboxButtonClickedBeforeBundle',
        {
          actionId: notificationData.id,
          isPrefetchCase: false,
          position: undefined,
          ruleId: idz.flags.proactif_activatedId!,
          vuid: idz.vuid,
        },
      );
    },
    onCurrentInputModeChange: (currentInputMode: InputDeviceTypes) =>
      notificationOrchestratorEmitter.emit(
        'currentInputModeChange',
        currentInputMode,
      ),
  };

  const findElementToAttach = (): HTMLElement | null => {
    const defaultContainer = document.body;
    if (!isEmbeddedConversationStarter) return document.body;
    const { domTarget, domTargetPosition } = (
      notificationData.notification as EmbeddedConversationStarterTemplate
    ).templateAttributes;
    const domTargetElement = document.querySelector(domTarget);
    if (!domTargetElement) return defaultContainer;

    if (
      document.getElementById('embeddedConversationStarterContainer') !== null
    ) {
      return null;
    }

    switch (domTargetPosition) {
      case 'AFTER':
        domTargetElement.after(embeddedConversationStarterContainer);
        break;
      case 'BEFORE':
        domTargetElement.before(embeddedConversationStarterContainer);
        break;
      case 'INSIDE':
        domTargetElement.appendChild(embeddedConversationStarterContainer);
        break;
    }
    return embeddedConversationStarterContainer;
  };

  const addEmbeddedConversationStarterStyle = () => {
    if (
      !embeddedConversationStarterContainer ||
      notificationData.notification.templateType !==
        'EMBEDDED_CONVERSATION_STARTER'
    ) {
      return;
    }
    const { templateAttributes } =
      notificationData.notification as EmbeddedConversationStarterTemplate;
    const { top, left, right, bottom, maxWidth } = templateAttributes;
    if (top !== undefined) {
      embeddedConversationStarterContainer.style.marginTop = `${top}px`;
    }
    if (left !== undefined) {
      embeddedConversationStarterContainer.style.marginLeft = `${left}px`;
    }
    if (right !== undefined) {
      embeddedConversationStarterContainer.style.marginRight = `${right}px`;
    }
    if (bottom !== undefined) {
      embeddedConversationStarterContainer.style.marginBottom = `${bottom}px`;
    }
    if (maxWidth !== undefined) {
      embeddedConversationStarterContainer.style.maxWidth = `${maxWidth}px`;
    }
    if (maxWidth !== undefined && left === undefined && right === undefined) {
      embeddedConversationStarterContainer.style.marginLeft = 'auto';
      embeddedConversationStarterContainer.style.marginRight = 'auto';
    }
  };
  addEmbeddedConversationStarterStyle();

  addIfDoesNotExistIframe(
    findElementToAttach(),
    globals,
    iFrameId,
    appContainerId,
    lang,
    iframeStyle,
    scripts,
    stylesheets,
    translationService('livechat.templatedNotification.iframe.label'),
  );

  if (
    notificationData.notification.templateType ===
    'EMBEDDED_CONVERSATION_STARTER'
  ) {
    return;
  }
  draggableInstance.setIsDraggableEnabled(
    notificationData.notification.id,
    globals.featureFlags,
  );
  if (draggableInstance.getIsDraggableEnabled()) {
    draggableInstance.initDraggable(
      document.getElementById(iFrameId),
      mediaType === 'MOBILE',
    );
  }
};

export const deleteNotification = (iframeId: string) => {
  if (draggableInstance.getIsDraggableEnabled()) {
    draggableInstance.removeDraggable(document.getElementById(iframeId));
  }
  //______________________________________________________________
  unmountRootNodeFromIframe(iframeId, appContainerId);
  destroyIframeByID(iframeId);
};
