import { DEVICE_TYPE } from '../constants';
import { ValueOf } from '../types/utils';

const deviceTypeToMediaType = (
  deviceType: ValueOf<typeof DEVICE_TYPE>,
): 'MOBILE' | 'DESKTOP' => {
  switch (deviceType) {
    case DEVICE_TYPE.MOBILE:
    case DEVICE_TYPE.TABLET:
      return 'MOBILE';
    case DEVICE_TYPE.DESKTOP:
    default:
      return 'DESKTOP';
  }
};

export default deviceTypeToMediaType;
