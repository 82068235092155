import { EmbeddedConversationStarterTemplate } from '@iadvize/notifications-library/esm/entities/notification';
import {
  ClassicParameters,
  FixedParameters,
  OffSiteChannelButton,
} from '../../shared/types/rules';
import { AllEvents } from '../../live/src/event-manager/types';
import {
  displayNotification,
  deleteNotification,
} from '../notificationRenderer';
import { getIdz } from '../../shared/globals';
import { isNotificationMinimizedFlagPrefix } from '../constants';
import { isPrefetchableRuleFactory } from '../../targeting/src/model/rule/action/actions/botScenarioPrefetcher';
import { makeIframeId } from '../helpers';
import { NotificationData, NotificationOrchestratorEmitter } from '../types';
import { prefetchBotScenario } from '../services/apiService';
import { redirectToMessenger } from '../offsiteManagers/messengerManager';
import { redirectToSMS } from '../offsiteManagers/smsManager';
import { redirectToWhatsapp } from '../offsiteManagers/whatsappManager';
import CustomEventEmitter from '../../shared/utils/CustomEventEmitter';
import DeviceInformationExtractor from '../../shared/DeviceInformationExtractor';
import getJSONStore from '../../shared/JSONStore';
import retrier from '../../shared/utils/retrier';
import {
  getStartersState,
  setStarterState,
} from '../../livechat/src/conversationFlow/startersState';
import { InputDeviceTypes } from '../../shared/InputDeviceTypes';

export default function notificationOrchestratorEffects(
  notificationOrchestratorEmitter: NotificationOrchestratorEmitter,
) {
  const globalEmitter = CustomEventEmitter<AllEvents>('dispatcher');

  notificationOrchestratorEmitter.on(
    'showNotification',
    ({ notificationData, routingRuleId, ruleId }) => {
      const { deviceType } = DeviceInformationExtractor();
      displayNotification(
        notificationData,
        deviceType,
        window.iAdvize.curlang,
        notificationOrchestratorEmitter,
        routingRuleId,
        ruleId,
      );
    },
  );

  notificationOrchestratorEmitter.on(
    'cancelNotification',
    ({ notificationId, templateType }) => {
      deleteNotification(
        makeIframeId({ id: notificationId } as NotificationData),
      );
      if (
        templateType === 'EMBEDDED_CONVERSATION_STARTER' &&
        document.getElementById('embeddedConversationStarterContainer') !== null
      ) {
        document
          .getElementById('embeddedConversationStarterContainer')
          ?.remove();
      }
    },
  );

  notificationOrchestratorEmitter.on(
    'closeNotification',
    (notificationData) => {
      const { notification, ruleId } = notificationData;

      const iframeNotificationId = makeIframeId(notificationData);
      const iframeNotification = document.getElementById(iframeNotificationId);
      if (!iframeNotification) {
        return;
      }
      iframeNotification.style.setProperty(
        'transition',
        'opacity 0.3s, transform 0.3s',
      );
      iframeNotification.style.setProperty('opacity', '0');
      if (notification.templateType !== 'INVITATION') {
        iframeNotification.style.setProperty('transform', 'translateY(100%)');
      }

      //_______________________________________________________
      setTimeout(() => {
        iframeNotification.style.setProperty('display', 'none');
        //_____________________________________________________________________________
        globalEmitter.emit('notification.close', notificationData.id, ruleId);
      }, 300);
    },
  );

  notificationOrchestratorEmitter.on('engagementAccepted', (payload) => {
    const idz = getIdz();
    const {
      notificationData,
      ruleId,
      channel,
      routingRuleId,
      position,
      optionalActionParameters,
    } = payload;

    const JSONStore = getJSONStore();
    const engagementRuleId = idz.proactifData.interactionRules.find(
      (rule) => rule.id === ruleId,
    )?.newId!;

    if (notificationData.parameters.type === 'EMBEDDED_CONVERSATION_STARTER') {
      const starterId = optionalActionParameters?.starterId || null;
      setStarterState({
        engagementRuleId,
        notificationId: notificationData.notification.id!,
        starterId,
        starters: (
          notificationData.notification as EmbeddedConversationStarterTemplate
        ).templateAttributes.starters,
      });
    } else {
      setStarterState(null);
    }

    globalEmitter.on('notification.clearStarterId', () => {
      const starterState = getStartersState();
      if (starterState) {
        setStarterState({ ...starterState, starterId: null });
      }
    });

    globalEmitter.emit('notification.clicked', {
      action: notificationData,
      ruleId,
      engagementRuleId,
      channel,
      optionalActionParameters,
    });

    const getIsPrefetchableRule = () => isPrefetchableRuleFactory(idz);

    //______________________________
    const isPrefetchCase =
      Boolean(routingRuleId) && getIsPrefetchableRule()(ruleId, routingRuleId);

    const button = (
      notificationData.parameters as ClassicParameters
    ).buttons?.find((_button) => _button.channel === channel);
    const buttons = (notificationData.parameters as ClassicParameters)
      .buttons || [(notificationData.parameters as FixedParameters).button];

    if (
      buttons?.some((b) => b?.channel === 'CHAT') &&
      routingRuleId &&
      isPrefetchCase
    ) {
      retrier(
        () =>
          prefetchBotScenario(
            routingRuleId,
            window.iAdvize.website_id,
            window.iAdvize.curlang,
          ),
        3,
        50,
      ).then(
        ({ data: messages }) => {
          const event = {
            routingRuleId,
            ruleId,
            messages,
            vuid: idz.vuid,
          };
          globalEmitter.emit(
            'notification.showChatboxWithPrefetchedScenario',
            event,
          );
        },
        () => {
          const event = {
            routingRuleId,
            ruleId,
            vuid: idz.vuid,
          };
          globalEmitter.emit(
            'notification.showChatboxWithPrefetchedScenarioFailed',
            event,
          );
        },
      );
    }

    const event = {
      ruleId,
      isPrefetchCase,
      position,
      vuid: idz.vuid,
      actionId: notificationData.id,
      notificationTemplate: notificationData.notification,
    };

    //_________________________________________________
    //_______________________________________
    idz.flags.proactif_activatedId = ruleId;
    //_________________________________________
    //__________________________________
    JSONStore.saveData({
      //_______________________________________
      proactif_activatedId: idz.flags.proactif_activatedId,
    });

    switch (channel) {
      case 'CHAT':
        globalEmitter.emit(
          'engagement.showChatboxButtonClickedBeforeBundle',
          event,
        );
        break;
      case 'CALL':
        globalEmitter.emit(
          'engagement.showCallButtonClickedBeforeBundle',
          event,
        );
        break;
      case 'VIDEO':
        globalEmitter.emit('conversations.visio.requestedBeforeBundle', event);
        break;
      case 'MESSENGER':
        redirectToMessenger(
          (button as OffSiteChannelButton).offsiteProfileExternalId,
        );
        break;
      case 'SMS':
        redirectToSMS(
          (button as OffSiteChannelButton).offsiteProfileExternalId,
        );
        break;
      case 'WHATSAPP':
        redirectToWhatsapp(
          (button as OffSiteChannelButton).offsiteProfileExternalId,
        );
        break;
      default:
        break;
    }
  });

  notificationOrchestratorEmitter.on(
    'minimizeNotification',
    (notificationData) => {
      const { ruleId } = notificationData;

      localStorage.setItem(
        `${isNotificationMinimizedFlagPrefix}`,
        String(true),
      );

      globalEmitter.emit('notification.minimized', {
        action: notificationData,
        ruleId,
      });
    },
  );

  notificationOrchestratorEmitter.on(
    'maximizeNotification',
    (notificationData) => {
      const { ruleId } = notificationData;

      localStorage.setItem(
        `${isNotificationMinimizedFlagPrefix}`,
        String(false),
      );

      globalEmitter.emit('notification.maximized', {
        action: notificationData,
        ruleId,
      });
    },
  );

  notificationOrchestratorEmitter.on(
    'currentInputModeChange',
    (currentInputMode: InputDeviceTypes) => {
      globalEmitter.emit(
        'notification.currentInputModeChange',
        currentInputMode,
      );
    },
  );
}
