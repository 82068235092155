import {
  NotificationOrchestratorEmitter,
  NotificationOrchestratorEvents,
} from '../types';
import notificationOrchestratorEffects from './notificationOrchestratorEffects';
import CustomEventEmitter from '../../shared/utils/CustomEventEmitter';

const notificationOrchestratorEmitter =
  CustomEventEmitter<NotificationOrchestratorEvents>(
    'notificationOrchestrator',
  ) as NotificationOrchestratorEmitter;

notificationOrchestratorEffects(notificationOrchestratorEmitter);

export default notificationOrchestratorEmitter;
