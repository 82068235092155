import { PrefetchedMessage } from '../../chatbox/chatbox-lib';
import { getIdz, getPlatform } from '../../shared/globals';
import fetchWithTimeout from '../../shared/utils/fetchWithTimeout';

export const prefetchBotScenario = (
  routingRuleId: string,
  websiteId: number,
  lang: string,
) => {
  const idz = getIdz();
  const platform = getPlatform();

  const path = `${idz.services['automation-bot-service']}scenarios/_prefetch`;
  const queryString = `routingRuleId=${routingRuleId}&websiteId=${websiteId}&lang=${lang}&platform=${platform}`;

  return fetchWithTimeout(
    `${path}?${queryString}`,
    +idz.engagement.prefetchBot.timeout,
  ).then(
    (res) => res.json() as Promise<{ data: { data: PrefetchedMessage }[] }>,
  );
};
