import { EmbeddedConversationStarterTemplate } from '@iadvize/notifications-library/esm/entities/notification';
import createState from '../../../shared/utils/createState';

export type StartersState = {
  starterId: string | null;
  engagementRuleId: string;
  notificationId: string;
  starters: EmbeddedConversationStarterTemplate['templateAttributes']['starters'];
};

export const [getStartersState, setStarterState] =
  createState<StartersState | null>(null);
