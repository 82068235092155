import { GlobalIdz } from '../../../../../../shared/types/globalTypes';
import { prefetchBotScenario } from '../../../../../../notificationOrchestrator/services/apiService';

export const isPrefetchableRuleFactory = (idz: GlobalIdz) => {
  const rawFeatureFlag =
    idz.featureFlags['iadvize.livechat.auto-engage-on-rules'];
  const activatedOnRules =
    !rawFeatureFlag || rawFeatureFlag === 'false'
      ? []
      : rawFeatureFlag.split(',');

  return (ruleId: number, routingRuleId: string) =>
    activatedOnRules.indexOf(ruleId.toString()) !== -1 ||
    activatedOnRules.indexOf(routingRuleId.toString()) !== -1 ||
    //__________________________________________________
    idz.ProActif._datas?.some(
      (rule) => rule.id === ruleId && rule.routingRuleIsBot,
    );
};

export default (idz: GlobalIdz) => {
  const { curlang, website_id: websiteId } = idz;
  const isPrefetchableRule = isPrefetchableRuleFactory(idz);

  return (ruleId: number, routingRuleId: string) => {
    if (!routingRuleId || !isPrefetchableRule(ruleId, routingRuleId)) {
      return Promise.resolve({ data: [] });
    }

    return prefetchBotScenario(routingRuleId, websiteId, curlang);
  };
};
